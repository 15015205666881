<template>
  <div>
    <b-col cols="12" class="mb-2">
      <b-button variant="primary" @click="onNew">新建调研流程</b-button>
    </b-col>

    <b-row>
      <b-col cols="3" v-for="(item, index) in queryData" :key="index">
        <b-card class="h-100" no-body>
          <b-card-header>
            <b-badge variant="light-primary" class="mr-1">
              <span>{{ stringToDate(item.start) }}</span>
              <feather-icon icon="ArrowRightIcon" class="mx-1" />
              <span>{{ stringToDate(item.end) }}</span>
            </b-badge>
            <!-- <b-card-title>{{item.title.substr(0,8)}}...</b-card-title> -->
          </b-card-header>

          <b-card-body>
            <div>
              <h4>{{ item.title }}</h4>
              <b-card-text>发起： {{ item.ownerName }} </b-card-text>
              <br /><br />
              <h5>简介： {{ item.summary }}</h5>
              <h5>
                <b-badge variant="light-secondary" class="mr-1" size="sm">{{
                  item.issueDate
                }}</b-badge>
                {{ item.issueNo }}
              </h5>
            </div>
            <div>{{ item.abstract }}</div>
          </b-card-body>

          <b-card-footer class="text-right">
            <b-button
              variant="flat-primary"
              size="sm"
              :disabled="item.eid == 1"
              @click="onView(item)"
              ><feather-icon icon="EditIcon" /> 查看</b-button
            >
            <b-button
              variant="flat-danger"
              size="sm"
              :disabled="item.eid == 1"
              @click="onEdit(item)"
              ><feather-icon icon="EditIcon" /> 编辑</b-button
            >
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <!-- pagination -->
    <b-col cols="12" class="mt-1">
      <b-pagination
        v-if="queryData"
        v-model="listTableCurrentPage"
        :total-rows="listTableTotalRows"
        :per-page="listTablePerPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardFooter,
  BCardText,
  BMedia,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BPagination,
  BBadge,
  BIconShieldLock,
} from "bootstrap-vue";
import FormWizardNumber from "./FormWizardNumber.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "DataMapProcessList",

  components: {
    BCard,
    BCardHeader,
    BCardFooter,
    BCardText,
    BMedia,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BPagination,
    BBadge,
    BIconShieldLock,
    FeatherIcon,
  },

  data() {
    return {
      queryData: [],
      listTableCurrentPage: 1,
      listTableTotalRows: 0,
      listTablePerPage: 10,
    };
  },

  computed: {
    ...mapState({
      eid: (state) => state.appOptions.eid,
      user: (state) => state.appOptions.user,
    }),
  },

  created() {
    this.loadData();
  },

  methods: {
    loadData() {
      this.loadEID();
      this.loadUser();

      this.$http.get("/datamaps").then((response) => {
        this.queryData = response.data.data.list;
        this.listTableTotalRows = response.data.data.total;
      });
    },

    stringToDate(sDate) {
      let date = new Date(sDate);
      return date.toISOString().split("T")[0];
    },

    onView(item) {
      this.$router.push(`/${this.eid}/datamap-file/${item.id}`);
    },

    onEdit(item) {
      this.$router.push(`/datamap-process/${item.id}`);
    },

    onNew() {
      this.$router.push("/datamap-process/new");
    },

    ...mapActions({
      loadEID: "appOptions/LOAD_EID",
      loadUser: "appOptions/LOAD_USER",
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
